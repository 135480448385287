// src/data/categories.js
const categories = [
    {
      name: 'Hogar',
      subcategories: ['Todo', 'Cocina', 'Decoración', 'Muebles'],
    },
    {
      name: 'Tecnología',
      subcategories: ['Todo', 'Smartphones', 'Computadoras', 'Accesorios'],
    },
    {
      name: 'Gaming',
      subcategories: ['Todo', 'Consolas', 'Accesorios Gaming', 'Juegos'],
    },
    {
      name: 'Belleza',
      subcategories: ['Todo', 'Perfumes', 'Cuidado de la Piel', 'Maquillaje'],
    },
    {
      name: 'Ropa',
      subcategories: ['Todo', 'Hombre', 'Mujer', 'Niños'],
    },
  ];
  
  export default categories;
  